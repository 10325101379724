<template>
  <div id="application-layout" class="application-layout">
    <site-header site-header></site-header>

    <main class="main-content d-flex flex-column align-items-center justify-content-center">
      <router-view></router-view>
    </main>

    <navigation v-if="showBottomNavigationBar()" />
  </div>
</template>

<script>
import SiteHeader from '../components/headers/SiteHeader.vue';
import Navigation from "../components/nav/Navigation.vue";

export default {
  name: 'ApplicationLayout',
  components: {
    SiteHeader,
    Navigation
  },

  methods: {
    showBottomNavigationBar() {
      return this.$route.meta.showBottomNav
    },
  },

  data() {
    return {

    };
  },
};
</script>

<style>
  @import '../assets/stylesheets/layouts/application_layout.scss';
</style>