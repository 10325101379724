<template>
  <div>
    <h1 class="d-flex justify-content-center mt-5 tipwise-primary">Edit Shift</h1>
    <span class="d-flex justify-content-center tipwise-grey mb-5">Edit your shift for {{ shift ? shift.date : 'this date' }}</span>

    <b-form 
      @submit="onSubmit"
      autocomplete="off"
    >
      <span class="tipwise-grey">Date</span>

      <div>
        <b-form-datepicker id="input-date" v-model="shift.date" class="mb-2" size="lg"></b-form-datepicker>
      </div>

      <span class="tipwise-grey">Job</span>

      <b-form-select
        id="input-3"
        v-model="shift.job_id"
        :options="getJobArray()"
        required
        class="mb-2 input-tipwise"
        size="lg"
      >
      </b-form-select>

      <span class="tipwise-grey">Hours worked</span>

      <b-form-input
        id="input-hours-worked"
        v-model="shift.hours_worked"
        type="number"
        step="0.01"
        required
        placeholder="hours worked"
        class="mb-2 input-tipwise"
        size="lg"
      >
      </b-form-input>

      <span class="tipwise-grey">Tips earned</span>

      <b-form-input
        id="input-tips-earned"
        v-model="shift.tips_earned"
        type="number"
        step="0.01"
        placeholder="hours worked"
        required
        class="mb-2 input-tipwise"
        size="lg"
      >
      </b-form-input>

      <span class="tipwise-grey">Tips paid out</span>
      <b-form-input
        id="input-tips-earned"
        v-model="shift.tips_paid_out"
        type="number"
        step="0.01"
        placeholder="hours worked"
        required
        class="mb-2 input-tipwise"
        size="lg"
      >
      </b-form-input>

      <div v-if="userSettings.track_total_sales">
        <span class="tipwise-grey">Total sales</span>
        <b-form-input
          id="input-tips-earned"
          v-model="shift.total_sales"
          type="number"
          step="0.01"
          :required="false"
          placeholder="Total sales"
          class="mb-2 input-tipwise"
          size="lg"
        >
        </b-form-input>
      </div>

      <b-button class="btn btn-tipwise w-100 mt-2" type="submit">
        Save
      </b-button>
      <router-link :to="{ name: 'view-shift', params: { id: this.shift.id }}" tag="button" class="btn btn-tipwise-white w-100 mt-2">
        Cancel
      </router-link>
      <p v-b-modal.delete-shift-modal class="text-center m-4 text-danger">
        Delete Shift
      </p>
    </b-form>

    <b-modal 
      id="delete-shift-modal"  
      centered
      hide-header
      hide-header-close
      hide-footer
      >
      <template #default="{ close }">
        <p class="text-center m-4">Are you sure you want to delete this?</p>

        <b-button variant="outline-danger" @click="deleteShift()" class="btn w-100 mt-2">
          DELETE
        </b-button>
        <b-button variant="primary" @click="close()" :disabled="awaitingResponse" class="btn btn-tipwise w-100 mt-2">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {

  data() {
    return {
        jobId: null,
        awaitingResponse: false,
        shift: {
          tips_earned: null,
          pay_rate: null,
          date: new Date().toISOString().slice(0,10),
          tips_paid_out: null,
          hours_worked: null,
          total_earned: null,
          total_sales: null,
          job_id: null,
        },
    };
  },

  beforeMount() {
    this.shift = this.$store.state.shifts.shifts.find(
      shift => shift.id === this.$route.params.id
    )

    this.jobId = this.$store.state.shifts.shifts.find(
      shift => shift.id == this.$route.params.id
    )['job_id']
  },

  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.awaitingResponse = true

      const shiftParams = {
        id: this.shift.id,
        tips_earned: parseFloat(this.shift.tips_earned),
        pay_rate: parseFloat(this.shift.pay_rate),
        date: this.shift.date,
        total_sales: parseFloat(this.shift.total_sales),
        tips_paid_out: parseFloat(this.shift.tips_paid_out),
        hours_worked: parseFloat(this.shift.hours_worked),
        job_id: this.shift.job_id,
      }
      
      this.$store.dispatch('shifts/update', { shift: shiftParams, jobId: this.jobId })
        .then(() => {
          this.$notify({
            group: 'default',
            type: 'success',
            text: 'Shift has been updated successfully',
          });
        })
        .then(() => { 
          this.awaitingResponse = true
          this.$router.push({ name: 'view-shift', params: { id: this.shift.id }} )          
        })
        .catch((error) => { 
          this.awaitingResponse = true
          console.log(error) 
        })
    },

    getJobArray() {
      const array = [{ value: null, text: 'Select Job', selected: true }];

      this.jobs.forEach((job) => {
        array.push({ value: job.id, text: job.name });
      })

      return array;
    },

    deleteShift() {
      this.$store.dispatch('shifts/delete', { shift: this.shift, jobId: this.jobId })
      .then(() => { this.$router.push({ name: 'home'}) })
    }
  },

  computed: {
    jobs() {
      return this.$store.state.jobs.jobs
    },

    userSettings() {
      return this.$store.state.settings.settings;
    },
  }
}
</script>

<style scoped>

</style>