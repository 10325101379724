import ajax from '../../ajax';
import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    shifts: [],
    filteredShifts: [],
    shiftReport: []
  },

  mutations: {
    set(state, data) {
      Object.assign(state, {
        shifts: data
      });
    },

    setFilteredShifts(state, data) {
      Object.assign(state, {
        filteredShifts: data
      });
    },

    setShiftReport(state, data) {
      Object.assign(state, {
        shiftReport: data
      });
    },

    updateShift(state, data) {
      const objIndex = state.shifts.findIndex((obj => obj.id === data.id));

      Object.assign(state.shifts[objIndex], data);
    },

    deleteShift(state, shiftId) {
      const shifts = state.shifts.map((shift) => shift.id != shiftId);

      Object.assign(state.shifts, shifts);
    },
  },

  actions: {
    clean({ commit }) {
      commit('set', {});
      commit('setFilteredShifts', {});
    },

    getAll({ commit }) {
      return new Promise((resolve, reject) => {
        ajax.get(`/user_shifts`)
        .then((response) => {
          commit('set', response.data );
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
      })
    },

    get({ commit }, jobId) {
      return new Promise((resolve, reject) => {
        ajax.get(`jobs/${jobId}/shifts`)
        .then((response) => {
          commit('set', response.data );
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
      })
    },

    create({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        ajax.post(`jobs/${payload.job_id}/shifts`, payload)
        .then(async (response) => {
          await dispatch('getAll');
          dispatch('get', payload.job_id);
          dispatch('calendarShifts/setLocalDate', { dates: new Date(payload.date), dot: true }, { root:true })
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
      })
    },

    update({ commit }, payload) {
      return new Promise((resolve, reject) => {
        ajax.put(`jobs/${payload.jobId}/shifts/${payload.shift.id}`, payload.shift)
          .then(async (response) => {
            await commit('updateShift', response.data)
            resolve(response)
          })
          .catch((error) => { 
            console.log(error);
            reject(error);
          })
      })
    },

    delete({ commit }, payload) {
      return new Promise((resolve, reject) => {
        ajax.delete(`jobs/${payload.jobId}/shifts/${payload.shift.id}`, payload.shift)
          .then(async (response) => {
            await commit('deleteShift', payload.shift.id)
            resolve(response)
          })
          .catch((error) => { 
            console.log(error);
            reject(error);
          })
      })
    },

    getFilteredShfits({ commit }, payload) {
      ajax.post(`user_shifts/filter`, payload)
        .then((response) => {
          commit('setFilteredShifts', response.data);
          return response;
        })
        .catch((error) => { throw error })
    },

    getReport({ commit }, payload) {
      ajax.post(`user_shifts/report`, payload)
        .then((response) => {
          commit('setShiftReport', response.data);
          return response;
        })
        .catch((error) => { throw error })
    },
  },
};
