<template>
	<div>
		<h1 class="text-center mb-2 tipwise-primary mt-5">Profile</h1>
		<span class="d-flex justify-content-center tipwise-grey mb-5">Edit your user account</span>

		<div class="content-box">
			<span class="tipwise-grey">Email</span>
			<b-form-input
        id="input-tips-earned"
        v-model="userProfile.email"
        type="email"
        placeholder="Email"
        required
        class="mb-2 input-tipwise"
        size="lg"
				disabled
      >
      </b-form-input>

			<span class="tipwise-grey">First Name</span>
			<b-form-input
        id="input-tips-earned"
        v-model="user.name"
        type="text"
        placeholder="First Name"
        required
        class="mb-2 input-tipwise"
        size="lg"
				:disabled="!this.editEnabled"
      >
      </b-form-input>

			<router-link tag="button" :to="{ name: 'settings' }" class="btn btn-tipwise-white m-1">Back</router-link>
			<button v-if="this.editEnabled" class="btn btn-tipwise m-1" @click="updateSettings()">Save</button>
			<button v-else class="btn btn-tipwise m-1" @click="toggleEdit()">Edit</button>

		</div>
	</div>
</template>

<script>
export default {
	title: 'Profile',
	name: 'Profile',

	metaInfo: {
    title: 'Profile',
  },
	
	data() {
		return {
			user: {
				name: null,
			},
			editEnabled: false
		}
	},

	mounted() {
		Object.keys(this.user).forEach((a) => this.user[a] = this.userProfile[a]);
	},

	computed: {
		userProfile() {
			return this.$store.state.user;
		}
	},

	methods: {
		toggleEdit() {
			this.editEnabled = !this.editEnabled;
		},

		updateSettings() {
			this.$store.dispatch('user/update', this.user)
				.then(() => { this.$notify({ group: 'default', text: 'Settings Updated!'}) })
				.then(() => { this.editEnabled = false })
        .catch(error => this.$notify({ group: 'default', type: 'error',  text: `Error: ${error}` }))
		}
	}


}
</script>

<style>

</style>