<template>
  <div class="navbar static-bottom fixed-bottom navigation row m-auto mt-5">
    <b-icon
      icon="bar-chart"
      font-scale="1.5"
      class="col-2"
      @click="$router.push({ name: 'home' })"
    />

    <b-icon
      icon="calendar-week"
      font-scale="1.5"
      class="col-2"
      @click="$router.push({ name: 'calendar' })"
    />


    <b-icon
        icon="plus-square"
        font-scale="2"
        class="col-2"
        @click="$router.push({ name: 'new-shift' })"
    />

    <b-icon
      icon="card-list"
      font-scale="1.5"
      class="col-2"
      @click="$router.push({ name: 'filter-shifts' })"
    />

    <b-icon
        icon="gear"
        font-scale="1.5"
        class="col-2"
        @click="$router.push({ name: 'settings' })"
    />
  </div>
</template>

<script>

export default {
  name: 'Navigation',

  data() {
    return {

    };
  },
};
</script>

<style>
  @import '../../assets/stylesheets/components/views/navigation.scss';
</style>