<template>
  <div class="donut-chart" id="donut">
    <div class="content-box donut-container">
      <span class="tipwise-grey text-center m-auto">Dashboard</span>
      <apexchart 
        type="radialBar" 
        class="chart m-auto" 
        :options="donut.chartOptions" 
        :series="[parseInt(calculateShiftData)]" 
        :labels="[`$ ${this.calculateShiftTotal}`]" 
      />

      <div class="text-center mt-3">
        <span @click="dateRangeInput = 'week'" :class="{ isActive: dateRangeInput == 'week' }" class="m-2">Week</span>
        <span @click="dateRangeInput = 'month'" :class="{ isActive: dateRangeInput == 'month'}" class="m-2">Month</span>
        <span @click="dateRangeInput = 'year'" :class="{ isActive: dateRangeInput == 'year'}" class="m-2">Year</span>
    </div>
    </div>

    <div class="container content-box donut-container">
      <div class="row ml-auto mr-auto  mt-2">

        <div class="col-3 text-center">
          <p class="small">Income</p>
          <p> {{ round(calculateTotalEarnings) }} </p>
        </div>

        <div class="col-1 m-auto">
          <div class="vertical-rule" />
        </div>


        <div class="col-3 text-center">
          <p class="small">Tips</p>
          <p> {{ round(calculateTotalTips) }} </p>
        </div>

        <div class="col-1 m-auto">
          <div class="vertical-rule" />
        </div>

        <div class="col-3 text-center">
          <p class="small">Total</p>

          <p> {{ round(calculateShiftTotal) }} </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numberFormatMixin from './Mixins/numberFormatMixin';
import toDateMixin from './Mixins/toDateMixin';

export default {

  mixins: [ numberFormatMixin, toDateMixin ],

  data() {
    return {
      dateRangeInput: 'week',
      shiftData: this.calculateShiftData,

      donut: {
        series: [this.calculateShiftData],
        chartOptions: {
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
              },
              legend: {
                position: 'bottom'
              },
              labels: ['Your Goal']
            }
          }],
        },
      },
    };
  },

  computed: {
    shifts() {
      return this.$store.state.shifts.shifts;
    },

    settings() {
      return this.$store.state.settings.settings
    },

    filteredShifts() {
      let dateRange = this.lastWeek;

      switch (this.dateRangeInput) {
        case 'week':
          dateRange = this.getFirstDayOfWeek(Date.now(), this.settings.first_day_of_week);
          break
        case 'month':
          dateRange = this.getFirstDayOfMonth();
          break;
        case 'year':
          dateRange = this.getFirstDayOfYear()
      }

      if (Object.keys(this.shifts).length === 0) return false;

      return this.shifts.filter((shift) => {
        const shiftDate = new Date(shift.date);

        return shiftDate.setHours(0,0,0,0) >= dateRange.setHours(0,0,0,0)
      })
    },

    calculateShiftData() {
      if (Object.keys(this.filteredShifts).length != 0) {
        let total = this.calculateShiftTotal

        return (total * 100) / this.$store.state.settings.settings[`goal_${this.dateRangeInput}`]
      }

      return 0;
    },

    calculateTotalTips() {
      if (Object.keys(this.filteredShifts).length == 0) return 0;

      return this.filteredShifts.reduce((previous, shift) => {
        return previous + shift.tips_earned;
      }, 0)
    },

    calculateTotalEarnings() {
      if (Object.keys(this.filteredShifts).length == 0) return 0;
      
      return this.filteredShifts.reduce((previous, shift) => {
        return previous + ((shift.hours_worked * shift.pay_rate) - shift.tips_paid_out);
      }, 0)
    },

    calculateShiftTotal() {
      if (Object.keys(this.filteredShifts).length == 0) return 0;
      
      return this.filteredShifts.reduce((previous, shift) => {
        return previous + parseFloat(shift.total_earned);
      }, 0.00)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '@/assets/stylesheets/components/donut-chart';
</style>
