import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    calculations: {}
  },

  mutations: {
    set(state, data) {
      Object.assign(state, {
        calculations: data
      });
    },
  },

  actions: {
    clean({ commit }) {
      commit('set', {});
    },

    get({ commit }) {
      ajax.get('/calculations')
        .then((response) => { commit('set', response.data )})
        .catch((error) => { throw error })
    },
  },
};
