<template>
	<div>
		<h1 class="text-center mb-2 tipwise-primary mt-5">Goals</h1>
		<span class="d-flex justify-content-center tipwise-grey mb-5">Configure Tipwise to suit you</span>

		<div class="content-box">
			<span class="tipwise-grey">Weeky Goal</span>
			<b-form-input
        id="input-tips-earned"
        v-model="goals.goal_week"
        type="number"
        step="0.01"
        placeholder="hours worked"
        required
        class="mb-2 input-tipwise"
        size="lg"
				:disabled="!this.editEnabled"
      >
      </b-form-input>

			<span class="tipwise-grey">Monthly Goal</span>
			<b-form-input
        id="input-tips-earned"
        v-model="goals.goal_month"
        type="number"
        step="0.01"
        placeholder="hours worked"
        required
        class="mb-2 input-tipwise"
        size="lg"
				:disabled="!this.editEnabled"
      >
      </b-form-input>

			<span class="tipwise-grey">Yearly Goal</span>
			<b-form-input
        id="input-tips-earned"
        v-model="goals.goal_year"
        type="number"
        step="0.01"
        placeholder="hours worked"
        required
        class="mb-2 input-tipwise"
        size="lg"
				:disabled="!this.editEnabled"
      >
      </b-form-input>

			<router-link tag="button" :to="{ name: 'settings' }" class="btn btn-tipwise-white m-1">Back</router-link>
			<button v-if="this.editEnabled" class="btn btn-tipwise m-1" @click="updateSettings()">Save</button>
			<button v-else class="btn btn-tipwise m-1" @click="toggleEdit()">Edit</button>

		</div>
	</div>
</template>

<script>
export default {
	title: 'Goals',
	name: 'Goals',

	mounted() {
		this.editEnabled = false;
		Object.keys(this.goals).forEach((goal) => this.goals[goal] = this.settings[goal]);
	},
	
	data() {
		return {
			goals: {
				id: null,
				goal_week: null,
				goal_month: null,
				goal_year: null,
			},
			editEnabled: false
		}
	},

	computed: {
		settings() {
			return this.$store.state.settings.settings;
		}
	},

	methods: {
		toggleEdit() {
			this.editEnabled = !this.editEnabled;
		},

		updateSettings() {
			this.$store.dispatch('settings/update', this.goals)
				.then(() => { this.$notify({ group: 'default', type: 'success', text: 'Settings Updated'}) })
				.then(() => { this.editEnabled = false })
        .catch(error => this.$notify({ group: 'default', type: 'error',  text: `Error: ${error}` }))
		}
	}


}
</script>

<style>

</style>