import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    jobs: {}
  },

  mutations: {
    set(state, data) {
      Object.assign(state, {
        jobs: data
      });
    },
  },

  actions: {
    clean({ commit }) {
      commit('set', {});
    },

    get({ commit }) {
      ajax.get('/jobs')
      .then((response) => {
        commit('set', response.data )
        return Promise.resolve(response.data)
      })
      .catch((error) => { return Promise.reject(error); })
    },

    create({ dispatch }, payload) {
      ajax.post('/jobs', payload)
      .then(() => { dispatch("get") })
      .catch((error) => { return Promise.reject(error); })
    },

    update({ commit }, payload) {
      const { id, ...updateData } = payload;

      return new Promise((resolve, reject) => {
        ajax.put(`jobs/${ id }`, updateData)
          .then(async (response) => {
            await commit('set', response.data)
            resolve(response)
          })
          .catch((error) => { 
            console.log(error);
            reject(error);
          })
      })
    },
  },
};
