<template>
<div>
  <b-navbar toggleable="md" type="light" class="top-nav">
    <b-img :src="require('../../assets/logo.png')" width="30" href="/"></b-img>
    <b-navbar-brand href="/" class="tipwise-secondary m-auto">
      <span class="font-weight-light">Tip</span>
      <span class="tipwise-primary"><b>Wise</b></span>
    </b-navbar-brand>

    <div v-if="user.auth_token" v-b-toggle="'nav-collapse'" class="d-md-none d-lg-none">
      <b-icon icon="grip-horizontal" font-scale="2" class="tipwise-grey"></b-icon>
    </div>

    <b-collapse id="nav-collapse" v-if="user.auth_token" is-nav>
      <b-navbar-nav>
        <router-link tag="b-nav-item" :to="{ name: 'home' }">Dashboard</router-link>
        <router-link tag="b-nav-item" :to="{ name: 'calendar' }">Calendar</router-link>
        <router-link tag="b-nav-item" :to="{ name: 'new-shift' }">New Shift</router-link>
        <router-link tag="b-nav-item" :to="{ name: 'filter-shifts' }">Reports</router-link>
        <router-link tag="b-nav-item" :to="{ name: 'settings' }">Settings</router-link>
        <!-- <b-nav-item href="#" disabled>Disabled</b-nav-item> -->
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <!-- <b-nav-form>
          <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
          <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
        </b-nav-form> -->


        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            {{ user.name || 'User' }}
          </template>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
          <b-dropdown-item @click="signOut()">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
import router from "@/router";

export default {
  computed: {
    user() {
      return this.$store.state.user
    },
  },

  methods: {
    signOut() {
      this.$store.dispatch('user/signOut')
        .then(() => { router.push({ name: 'sign-in' }) })
    }
  }

}
</script>

<style scoped>
 @import '../../assets/stylesheets/components/headers/site-header.scss';
</style>
