<template>
  <div id="app">
    <component :is="layout"></component>
    <notifications position="top center " group="default" classes="tipwise-notification" />
  </div>
</template>

<script>
import ApplicationLayout from './layouts/ApplicationLayout.vue';

export default {
  
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Start Tracking Your Earnings',
    // all titles will be injected into this template
    titleTemplate: 'Tipwise | %s',
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Tipwise App lets you keep on top of your earnings by tracking your tips and hours worked in one place - Stop guessing and get Tipwise' },

      // Open Graph / Facebook
      { property:"og:type", content: "website" },
      { property:"og:url", content: "https://tipwiseapp.com/" },
      { property:"og:title", content: "Tipwise | Start Tracking Your Earnings" },
      { property:"og:description", content: "Tipwise App lets you keep on top of your earnings by tracking your tips and hours worked in one place - Stop guessing and get Tipwise" },
      { property:"og:image", content: `https://www.tipwiseapp.com${require('./assets/tipwise-logo-card.jpg')}` || '' },

      // Twitter
      { property: "twitter:card", content: "summary_large_image" },
      { property: "twitter:url", content: "https://tipwiseapp.com/" },
      { property: "twitter:title", content: "Tipwise | Start Tracking Your Earnings" },
      { property: "twitter:description", content: "Tipwise App lets you keep on top of your earnings by tracking your tips and hours worked in one place - Stop guessing and get Tipwise" },
      { property: "twitter:image", content: `https://www.tipwiseapp.com${require('./assets/tipwise-logo-card.jpg')}` || '' },
    ]
  },

  components: {
    'application-layout': ApplicationLayout,
  },

  data() {
    return {
    };
  },

  computed: {
    layout() {
      return 'application-layout';
    },
  },
};
</script>

<style lang="scss">
  @import 'assets/stylesheets/application.scss';
  @import 'assets/stylesheets/components/notification.scss';
</style>