import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    name: '',
    email: '',
    auth_token: '',
    referralCode: ''
  },

  getters: {
    authenticated: state => !!state.auth_token,
  },

  mutations: {
    set(state, data) {
      Object.assign(state, {
        name: data.name,
        email: data.email,
        referralCode: data.referralCode
      });
    },

    setAuth(state, data) {
      Object.assign(state, {
        auth_token: data.auth_token,
      });
    },
  },

  actions: {
    clean({ commit }) {
      commit('set', {});
    },


    get({ commit }) {
      ajax.get('/users')
      .then((response) => {
        commit('set', { name: response.data.name, email: response.data.email, referralCode: response.data.referral_code  })
        return Promise.resolve(response.data)
      })
      .catch((error) => { 
        console.log(error);
        throw error 
      })
    },

    update({ commit }, payload) {
      ajax.put(`/users/1`, payload) // an id is required for rails to route properly. user is found by token
      .then((response) => { commit('set', response.data) })
      .catch((error) => { return Promise.reject(error); })
    },

    // handle all of the sign in logic inside of here
    signIn({ commit }, payload ) {
      return new Promise((resolve, reject) => {
        ajax.post('/auth/login' , { authentication: payload })
        .then((response) => {
          commit('setAuth', { auth_token: response.data.auth_token })
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
      })
    },

    signUp({ commit }, payload) {
      return new Promise((resolve, reject) => {
        ajax.post('/signup', payload)
        .then((response) => {
          commit('set', { name: response.data.name, email: response.data.email });
          commit('setAuth', { auth_token: response.data.auth_token })
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
      })
    },


    forgotPassword({}, payload) {
      return new Promise((resolve, reject) => {
        ajax.post(`/password/forgot`, payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
      })
    },

    resetPassword({}, payload) {
      return new Promise((resolve, reject) => {
        ajax.post(`/password/reset`, payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
      })
    },

    signOut({ commit, dispatch }) {
      commit('set', { name: '', email: '' });
      commit('setAuth', { auth_token: '' })
      dispatch('jobs/clean', {}, { root: true })
      dispatch('shifts/clean', {}, { root: true })
      dispatch('settings/clean', {}, { root: true })
      dispatch('calendarShifts/clean', {}, { root: true })
    }
  },
};
