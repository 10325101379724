<template>
  <div class="job-settings mt-5">
    <h1 class="text-center mb-2 tipwise-primary">Jobs</h1>
    <span class="d-flex justify-content-center tipwise-grey mb-5">Here is a list of all of your jobs</span>

    <button @click="$router.push({ name: 'new-job' })" class="btn btn-tipwise mt-2 w-100" >New Job</button>
		<router-link tag="button" :to="{ name: 'settings' }" class="btn btn-tipwise-white mt-2 w-100">Back</router-link>


		<div class="content-box mt-4">
			<span 
				class="d-flex justify-content-center tipwise-grey mb-5"
				v-if="disabledJobs.length > 0"
				@click="toggleShowDisbaledJobs()"
			>
				{{ showDisabledJobs ? 'Hide disabled jobs' : 'Show disabled jobs' }}
			</span>

			<div v-for="enabledJob in enabledJobs" :key="`job-${enabledJobs.id}`">
				<router-link tag="div" :to="{ name: 'view-job', params: { id: enabledJob.id } }" class="job-list-tile d-flex align-items-center">
					<div class="row d-flex w-100">
						<div class="col-4 mt-auto mb-auto">
							<avatar class="mt-auto ml-auto" :size="40" :username="enabledJob.name"></avatar>
						</div>
						<div class="col-4 mt-auto mb-auto text-left">
							<span>{{ enabledJob.name }}</span>
						</div>
					</div> 
				</router-link>
			</div>

			<div v-show="showDisabledJobs" v-for="disabledJob in disabledJobs" :key="`disabled-job-${disabledJob.id}`">
				<router-link tag="div" :to="{ name: 'view-job', params: { id: disabledJob.id } }" class="job-list-tile d-flex align-items-center">
					<div class="row d-flex w-100">
						<div class="col-4 mt-auto mb-auto">
							<avatar class="mt-auto ml-auto" :size="40" :username="disabledJob.name"></avatar>
						</div>
						<div class="col-4 mt-auto mb-auto text-left">
							<span>{{ disabledJob.name }}</span>
						</div>
						<div class="col-4 mt-auto mb-auto text-right">
							<b-icon
								icon="eye-slash"
								font-scale="1.5"
							/>
						</div>
					</div> 
				</router-link>
			</div>
			</div>
		</div>

  </div>
</template>

<script>
export default {
	title: 'Job List',
	
	data() {
		return {
			showDisabledJobs: false,
		}
	},

  computed: {
    jobs() {
      return this.$store.state.jobs.jobs;
		},
		
		disabledJobs() {
			return this.jobs.filter((job) => job.disabled);
		},

		enabledJobs() {
			return this.jobs.filter((job) => !job.disabled);
		},
	},
	
	methods: {
		toggleShowDisbaledJobs() {
			this.showDisabledJobs = !this.showDisabledJobs;
		}
	}
}
</script>

<style lang="scss" scoped>
	@import '../../assets/stylesheets/components/settings/job-settings';
</style>