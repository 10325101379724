<template>
  <form action="" @submit.prevent="forgotPassword()">
    <div class="vh-90 d-flex justify-content-center align-items-center">
    <div class="content-box sign-in">
      <h1 class="d-flex justify-content-center tipwise-primary">Forgot password</h1>
      <span class="d-flex justify-content-center tipwise-grey mb-5">Don't worry, it happens 😅</span>


      <div class="mt-5">
        <b-form-input v-model="credentials.email" placeholder="email" type="email" size="lg" class="mt-2 input-tipwise"/>
      </div>

      <b-link tag="span" class="tipwise-primary" :to="{ name: 'sign-in' }">Back</b-link>

      <div class="d-flex justify-content-center mt-5">
        <b-button type="submit" variant="tipwise-white">
          Get reset link
        </b-button>
      </div>
    </div>
  </div>
  </form>
</template>

<script>
import router from '@/router/index'

export default {
    name: 'SignIn',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Sign In'
    },

  data(){
    return{
      credentials: {
        email: '',
      },
      email_sent: false
    }
  },

  methods: {
     forgotPassword() {
      if (this.email_sent == true) {
          this.$notify({ group: 'default', text: 'Email has been sent, you should get it shortly. If not click get reset link again' });
          this.email_sent = false;
          return false;
      }

       this.$store.dispatch("user/forgotPassword", this.credentials)
        .then((response) => { this.$notify({ group: 'default',  text: response.data.message }) })
        .then(() => this.email_sent = true ) 
        .catch((error) => { 
          this.$notify({ 
            group: 'default', 
            text:`Error: ${ error.response.data.message } 💩`,
            type: 'error'
          } ); })
    },
  },
};
</script>
