<template>
<div>
    <h1 class="d-flex justify-content-center mt-5 tipwise-primary">Shift Report</h1>
    <span class="d-flex justify-content-center tipwise-grey mb-5">Shift report for {{ job.name }} on {{ shift.date }}</span>

		<div class="content-box">
			<div v-if="shift.total_sales">
				<div class="d-flex mr-3 ml-3 mb-3">	
					<span>Total Sales:</span>	 
					<span class="ml-auto">${{ round(shift.total_sales) }}</span>
				</div>
				<div class="d-flex mr-3 ml-3 mb-3">	
					<span>Average tip percentage</span>	 
					<span class="ml-auto">{{ round((shift.tips_earned/shift.total_sales)*100, 0) }}%</span>
				</div>		
				<hr class="mt-2 mb-2">
			</div>	
			
			<div class="d-flex mr-3 ml-3 mt-3">	
				<span>Pay Rate:</span>	 
				<span class="ml-auto">${{ round(shift.pay_rate) }}</span>
			</div>
			<div class="d-flex mr-3  ml-3">	
				<span></span>	 
				<span class="ml-auto">x</span>
			</div>			
			<div class="d-flex mr-3 mb-3 ml-3">	
				<span>Hours worked:</span>	 
				<span class="ml-auto">{{ round(shift.hours_worked) }}</span>
			</div>			
			<hr class="mt-2 mb-2">
			<div class="d-flex mr-3 mb-3 ml-3">	
				<span>Total wage:</span>	 
				<span class="ml-auto">${{ round((shift.hours_worked * shift.pay_rate)) }}</span>
			</div>	
			<div class="d-flex mr-3 mb-3 ml-3">	
				<span>Tips Earned:</span>	 
				<span class="ml-auto">${{ round(shift.tips_earned) }}</span>
			</div>
			<div class="d-flex mr-3 mb-3 ml-3">	
				<span>Tips paid out:</span>	 
				<span class="ml-auto">${{ round(shift.tips_paid_out) }}</span>
			</div>
			<hr class="mt-2 mb-2">
			<div class="d-flex mr-3 ml-3 mb-3">	
				<span>Total Earned:</span>	 
				<span class="ml-auto">${{ round(shift.total_earned) }}</span>
			</div>
			<hr class="mt-2 mb-2">

		</div>
		<router-link :to="{ name: 'edit-shift', params: { id: this.shift.id } }" tag="button" class="btn btn-tipwise w-100 mt-2">
			Edit Shift
		</router-link>
		<router-link :to="{ name: 'new-shift' }" tag="button" class="btn btn-tipwise-white w-100 mt-2">
			Back
		</router-link>
</div>
</template>

<script>
import numberFormatMixin from "../../components/Mixins/numberFormatMixin";

export default {
	mixins: [numberFormatMixin],

  computed: {
		shift() {
			return this.$store.state.shifts.shifts.find(shift => shift.id == this.$route.params.id);
		},

		job(){
			return this.$store.state.jobs.jobs.find(job => job.id == this.shift.job_id);
		},

		userSettings() {
      return this.$store.state.settings.settings;
    },
	},
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/components/views/shifts/view-shift.scss';
</style>