import  store from './store';
import router from './router/index';


let axios = require('axios');

const ajax = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

ajax.interceptors.request.use((config) => {
  if (localStorage.vuex && JSON.parse(localStorage.vuex)["user"]["auth_token"] !== "") {
    config.headers = {
      ...config.headers, 'Authorization': JSON.parse(localStorage.vuex)["user"]["auth_token"]
    }
  }
  return config;
});

ajax.interceptors.response.use((response) => {
    return response;
  }, async function (error) {
  if (error.response.status === 401 && error.response.data.message === 'Signature has expired') {
    console.log('token has expired')
    await store.dispatch('user/signOut');
    window.location.replace("/");
  }
    return Promise.reject(error);
}, store);

export default ajax;
