<template>
	<div>
		<h1 class="text-center mb-2 tipwise-primary mt-5">Goals</h1>
		<span class="d-flex justify-content-center tipwise-grey mb-5">Configure Tipwise to suit you</span>

		<div class="content-box">
			<span class="tipwise-grey">Name</span>
			<b-form-input
        id="input-tips-earned"
        v-model="job.name"
        type="text"
        step="0.01"
        placeholder="name"
        required
        class="mb-2 input-tipwise"
        size="lg"
				:disabled="!this.editEnabled"
      >
      </b-form-input>

			<span class="tipwise-grey">Pay rate</span>
			<b-form-input
        id="input-tips-earned"
        v-model="job.base_pay"
        type="number"
        step="0.01"
        placeholder="Pay Rate"
        required
        class="mb-2 input-tipwise"
        size="lg"
				:disabled="!this.editEnabled"
      >
      </b-form-input>

			<b-button v-if="this.editEnabled" class="btn btn-tipwise w-100 mt-2" @click="updateJob()" type="submit">
        Save
      </b-button>

			<button v-else class="btn btn-tipwise w-100 mt-2" @click="toggleEdit()">
				Edit
			</button>

      <router-link tag="button" :to="{ name: 'view-job', params: { id: this.$route.params.id } }" class="btn btn-tipwise-white w-100 mt-2">Back</router-link>
			
			<p v-b-modal.disable-job-modal ref='btnDisable' class="text-center m-4 text-danger">
				{{ currentJob.disabled ? "ENABLE" : 'DISABLE' }}
      </p>

			<b-modal 
				id="disable-job-modal"  
				centered
				hide-header
				hide-header-close
				hide-footer
			>
      <template #default="{ close }">
        <p class="text-center m-4">
					Disabling this job hides it from view. It can be re-endabled at any time
				</p>

        <b-button variant="outline-danger" @click="toggleEnabled()" class="btn w-100 mt-2">
					{{ currentJob.disabled ? "ENABLE" : 'DISABLE' }}
        </b-button>

        <b-button variant="primary" @click="close()" class="btn btn-tipwise w-100 mt-2">
          Cancel
        </b-button>

      </template>
    </b-modal>

		</div>
	</div>
</template>

<script>
export default {
	title: 'Edit Job',
	name: 'editJob',

	mounted() {
    this.editEnabled = false;
		Object.keys(this.job).forEach((job) => this.job[job] = this.currentJob[job]);
	},
	
	data() {
		return {
			job: {
        id: null,
				name: null,
				base_pay: null,
			},

			editEnabled: false,
		}
	},

	computed: {
		currentJob() {
			return this.$store.state.jobs.jobs.find((job) => job.id === this.$route.params.id);
		}
	},

	methods: {
		toggleEdit() {
			this.editEnabled = !this.editEnabled;
		},

		updateJob() {
			this.$store.dispatch('jobs/update', this.job)
				.then(() => { this.$notify({ group: 'default', text: 'Job Updated'}) })
				.then(() => { this.editEnabled = false })
        .catch(error => this.$notify({ group: 'default', type: 'error',  text: `Error: ${error}` }))
		},

		toggleEnabled() {
			this.$store.dispatch('jobs/update', { id: this.job.id, disabled: !this.currentJob.disabled })
				.then(() => { this.$notify({ group: 'default', text: 'Job Updated'}) })
				.then(() => { this.$root.$emit('bv::hide::modal', 'disable-job-modal', '#btnDisable') })
        .catch(error => this.$notify({ group: 'default', type: 'error',  text: `Error: ${error}` }))
		},
	}


}
</script>

<style>

</style>