<template>
  <div class="calendar content-box mt-5 text-center">
    <span class="tipwise-grey text-center mt-5">Calendar</span>
    <div class="calendar mt-2">
    </div>
      <v-date-picker 
        id="testcal"
        ref="calendar"
        class="fixed"
        :attributes="calendarAttributes"
        :first-day-of-week='firstDayOfWeek'
        v-model="currentDateSelection"
        is-expanded
        is-required
      />
    <div>
      <router-link :to="{ name: 'new-shift' }" tag="button" class="btn btn-tipwise-white w-100 mt-2">
        New Shift
      </router-link>

      <p class="text-center mt-4">
        Shifts
      </p>

      <hr>

      <div v-for="shift in selectedShifts" :key="shift.id" >
        <list-tile :shift="shift" :returnPath="'calendar'" />
      </div>

      <div v-if="selectedShifts.length == 0">
        <p class="text-center tipwise-grey">
          No shift records for this date
        </p>
        <p @click="$router.push({ name: 'new-shift' })" class="text-center tipwise-primary">
          click here to add one
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ListTile from "@/components/ListTile";

export default {
  title: 'Calendar',

  metaInfo: {
    title: 'Calendar',
  },

  components: {
    ListTile
  },

  // populate calendar with user shifts 
  mounted() {
    this.shifts.map((shift) => { this.calendarAttributes.push({ dot: true, dates: new Date(`${shift.date}T00:00:00`) }) });
    this.calendarAttributes.push({
      key: 'today',
      dates: new Date(),
      highlight: {
        color: 'purple',
        fillMode: 'light',
      },
    })
  },

  data() {
    return {
      // Attributes are supplied as an array
      calendarAttributes: [],
      currentDateSelection: new Date(),
    }
  },

  computed: {
    shifts() {
      return this.$store.state.shifts.shifts;
    },

    firstDayOfWeek() {
      return this.$store.state.settings.settings.first_day_of_week || 2;
    },

    selectedShifts() {
      if (!this.shifts) return [];

      return this.shifts.filter((shift) => {
        // It's not a stupid solution if it works
        return shift.date == this.currentDateSelection.toLocaleDateString('af-NA', { year: 'numeric', month: '2-digit', day: '2-digit' });
      }, this)
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/components/views/calendar.scss';
</style>
