<template>
  <div class="mt-5 settings">
    <h1 class="text-center mb-2 tipwise-primary">Settings</h1>
    <span class="d-flex justify-content-center tipwise-grey mb-5">Configure Tipwise to suit you</span>

    <router-link tag="div" :to="{ name: 'profile-settings'}" class="m-4 d-flex justify-content-between setting-link">
      <span>Your Profile</span>
      <span>
        <b-icon icon="chevron-right" font-scale="1" class="mr-auto color-tipwise-secondary"></b-icon>
      </span>
    </router-link>

    <router-link tag="div" :to="{ name: 'shift-settings'}" class="m-4 d-flex justify-content-between setting-link">
      <span>Shift Settings</span>
      <span>
        <b-icon icon="chevron-right" font-scale="1" class="mr-auto color-tipwise-secondary"></b-icon>
      </span>
    </router-link>

    <router-link tag="div" :to="{ name: 'calendar-settings'}" class="m-4 d-flex justify-content-between setting-link">
      <span>Calendar Settings</span>
      <span>
        <b-icon icon="chevron-right" font-scale="1" class="mr-auto color-tipwise-secondary"></b-icon>
      </span>
    </router-link>

    <router-link tag="div" :to="{ name: 'job-settings'}" class="m-4 d-flex justify-content-between setting-link">
      <span>Manage Jobs</span>
      <span>
        <b-icon icon="chevron-right" font-scale="1" class="mr-auto color-tipwise-secondary"></b-icon>
      </span>
    </router-link>

    <router-link tag="div" :to="{ name: 'goal-settings'}" class="m-4 d-flex justify-content-between setting-link">
      <span>Earning Goals</span>
      <span>
        <b-icon icon="chevron-right" font-scale="1" class="mr-auto color-tipwise-secondary"></b-icon>
      </span>
    </router-link>

    <router-link tag="div" :to="{ name: 'welcome'}" class="m-4 d-flex justify-content-between setting-link">
      <span>Read this</span>
      <span>
        <b-icon icon="chevron-right" font-scale="1" class="mr-auto color-tipwise-secondary"></b-icon>
      </span>
    </router-link>

  </div>
</template>

<script>

export default {
  title: 'Settings',
  name: 'Settings',

  metaInfo: {
    title: 'Settings',
  },

  data() {
    return {

    }
  },

  beforeMount() {
    this.$store.dispatch('settings/get')
      .catch((error) => { console.log(error) })
  },

  computed: {

  },

  methods: {
    saveSettingData() {
      this.$store.dispatch('settings/update', { id: this.settingsId, data: this.settingsFormData })
        .then(() => { this.$notify({ group: 'default', type: 'success', text: 'Settings Updated'}) })
        .catch(error => this.$notify({ group: 'default', type: 'error',  text: `Error: ${error}` }))
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '../../../assets/stylesheets/components/headers/settings.scss';
</style>
