<template>
  <form action="" @submit.prevent="signIn()">
    <div class="vh-90 d-flex justify-content-center align-items-center">
    <div class="content-box sign-in">
      <h1 class="d-flex justify-content-center tipwise-primary">Sign In</h1>
      <div class="mt-5">
        <b-form-input v-model="credentials.email" placeholder="email" type="email" size="lg" class="mt-2 input-tipwise"/>
        <b-form-input v-model="credentials.password" placeholder="password" type="password" size="lg" class="mt-2 input-tipwise"/>
        <b-link class="tipwise-primary" :to="{ name: 'forgot-password' }">Forgot password</b-link>
        <br>
        <b-link class="tipwise-primary" :to="{ name: 'sign-up' }">don't have an account?</b-link>
      </div>
      <div class="d-flex justify-content-center mt-5">
        <b-button type="submit" variant="tipwise-white">
          Sign In
        </b-button>
      </div>
    </div>
  </div>
  </form>
</template>

<script>
import router from '@/router/index'

export default {
    name: 'SignIn',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Sign In'
    },

  mounted() {
    if (this.$route.params.email) { this.email = this.$route.params.email };
  },

  data(){
    return{
      credentials: {
        email: '',
        password: '',
      },
    }
  },

  methods: {
     signIn() {
       this.$store.dispatch("user/signIn", this.credentials)
        .then(async () => await this.$store.dispatch('user/get'))
        .then(async () => await this.$store.dispatch('shifts/getAll'))
        .then(async () => await this.$store.dispatch('jobs/get'))
        .then(async () => await this.$store.dispatch('settings/get'))
        .then(async () => await router.push({ name: 'home' }))
        .then(async () => await this.$store.dispatch('calendarShifts/setAll'))
        .catch((error) => { 
          this.$notify({ 
            group: 'default', 
            text:`Error: ${ error.response.data.message } 💩`,
            type: 'error'
          } ); })
    },
  },
};
</script>

<style scoped>
  @import '../../assets/stylesheets/components/views/auth/sign-in.scss';
</style>
