<template>
  <div>
    <h1 class="d-flex justify-content-center mt-5 tipwise-primary">Reports</h1>
    <span class="d-flex justify-content-center tipwise-grey mb-5">here you can create filter shift records</span>

    <div class="calendar content-box mt-5 text-center">
      <h4 class="d-flex justify-content-center tipwise-primary" v-b-toggle.collapse-filter>
        <span>
          Search
        </span> 
         <span class="ml-2">
          <b-icon icon="chevron-down" font-scale="1" class="color-tipwise-secondary"></b-icon>
         </span>
      </h4>
      <b-collapse id="collapse-filter" ref="collapseFilter" visible>
        <b-form @submit="onSubmit" class="new-shift-box">
          <span class="tipwise-grey">Job</span>
            <b-form-select
              id="input-3"
              v-model="form.job_id"
              :options="getJobArray()"
              class="mb-2 input-tipwise"
              size="lg"
            />
            <span class="tipwise-grey">Date From</span>
            <b-form-datepicker id="input-date-from" v-model="form.date_from" class="mb-2 input-tipwise" size="lg"></b-form-datepicker>
          
            <span class="tipwise-grey">Date To</span>
            <b-form-datepicker id="input-date-to" v-model="form.date_to" class="mb-2 input-tipwise mb-2" size="lg"></b-form-datepicker>

            <div class="m-auto">
              <b-button class="btn btn-tipwise-white m-1 w-100  text-center" type="reset">Reset</b-button>
              <b-button class="btn btn-tipwise m-1 w-100  text-center" type="submit">Filter</b-button>
              <b-button variant="outline-dark" class="m-1 w-100 text-center" @click="getReport()">Generate report</b-button>
            </div>
          </b-form>
      </b-collapse>
      </div>

      <div class="calendar content-box mt-5 text-center">
        <h4 class="d-flex justify-content-center tipwise-primary">Results</h4>
        <div class="recent-shifts-container mb-5">
            <div class="">
              <div v-for="shift in filteredShifts" :key="`recent-shift-${shift.id}`">
                <list-tile :shift="shift" :returnPath="'home'" />
              </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import ListTile from "@/components/ListTile";

export default {
  metaInfo: {
      title: 'Reports',
  },

  data() {
    return {
      form: {
        job_id: null,
        date_from: null,
        date_to: null,
      },
    };
  },

  components: {
    ListTile
  },

  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$store.dispatch('shifts/getFilteredShfits', this.form)
        .catch((error) => { console.log(error) })
    },

    getReport() {
      this.$store.dispatch('shifts/getReport', this.form)
        .then(() => {
          this.$router.push({ name: 'report', params: this.form })
        })
        .catch((error) => { 
          this.$notify({
            group: 'error',
            title: 'Error',
            text: error,
          });
        })
    },

    getJobArray() {
      const array = [{ value: null, text: 'All Jobs', selected: true }];

      this.jobs.forEach((job) => {
        array.push({ value: job.id, text: job.name });
      })

      return array;
    },
  },

  computed: {
    jobs() {
      return this.$store.state.jobs.jobs
    },

    filteredShifts() {
      return this.$store.state.shifts.filteredShifts;
    },
  }
}
</script>

<style lang="scss" scoped>
  // @import '../../assets/stylesheets/components/views/shifts/new-shift.scss';
</style>