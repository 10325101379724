<template>
	<div>
		<h1 class="text-center mb-2 tipwise-primary mt-5">404</h1>
		<span class="d-flex justify-content-center tipwise-grey mb-5">Page not found</span>

		<div class="content-box">
			<p class="m-2 text-center" >You look a little lost..</p>
      <p class="m-2 text-center" >The button below will bring you back home</p> 

			<router-link tag="button" :to="{ name: 'home' }" class="btn btn-tipwise-white m-1 w-100  text-center">Home</router-link>

		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>