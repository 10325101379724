<template>
	<div class="landing">

    <div ref="section-1" class="landing-section">
      <div class="center-content text-center tipwise-primary-bg">
        <div class="m-4">
          <b-img :src="require('@/assets/logo.png')" width="75" rounded="circle" class="bg-light" alt="tipwise">
          </b-img>
        </div>

        <h1 class="mt-auto text-white">Tipwise</h1>
        <p class="text-white mr-2 ml-2">Tipwise is a tool that helps track tips, hours, goals and create reports</p>
        <p class="text-white mr-2 ml-2">Finally a tip tracker that isn't garbage</p>


        <router-link :to="{ name: 'sign-up' }" custom v-slot="{ navigate }">
          <button @click="navigate" @keypress.enter="navigate" role="link" class="btn btn-tipwise-white btn-lg mt-5">Sign Up</button>
        </router-link>

        <router-link :to="{ name: 'sign-in' }" custom v-slot="{ navigate }">
          <p class="mt-5 text-white" @click="navigate" @keypress.enter="navigate">Already Registered?</p>
        </router-link>

        <!-- <p class="mt-5 text-white" @click="this.$router.push({ name: 'sign-in' })">Already Registered?</p> -->

      </div>
      
      <div class="text-center"
        @click="scrollToElement('section-2')"
      >
        <b-icon
          icon="arrow-down-circle"
          font-scale="3"
          class="tipwise-primary d-flex mb-auto mt-md-5 mt-2 ml-auto mr-auto"
        />
        <p class="tipwise-grey mr-2 ml-2 mt-2">How it works</p>
      </div>
    </div>

    <div ref="section-2" class="landing-section tipwise-secondary-bg">

      <div class="center-content text-center">
        <b-icon
          icon="clipboard-data"
          font-scale="4"
          class="tipwise-secondary d-flex mb-3 ml-auto mr-auto"
        />
        <h1 class="mt-auto tipwise-primary">Track your earnings</h1>
        <p class="tipwise-grey ml-2 mr-2 mb-5">Keep a record of each shift you work and the money you made</p>

        <p class="tipwise-grey ml-2"><span class="tipwise-primary mr-2">•</span>Tips earned</p>
        <p class="tipwise-grey ml-2"><span class="tipwise-primary mr-2">•</span>Hours worked</p>
        <p class="tipwise-grey ml-2"><span class="tipwise-primary mr-2">•</span>Tips paid out</p>
        <p class="tipwise-grey ml-2"><span class="tipwise-primary mr-2">•</span>Hourly wage</p>

        <p class="tipwise-grey ml-2 mr-2 mt-5">Then we calculate the total and see how much you made </p>

      </div>
      
      <div class="text-center text-white tipwise-secondary-bg"
        @click="scrollToElement('section-3')"
      >
        <b-icon
          icon="arrow-down-circle"
          font-scale="3"
          class="text-white d-flex mb-auto mt-5 ml-auto mr-auto"
        />
        <p class="text-white mr-2 ml-2 mt-2">And then</p>
      </div>

    </div>

    <div ref="section-3" class="landing-section ">

      <div class="center-content text-center tipwise-secondary-bg">
        <b-icon
          icon="bar-chart-line"
          font-scale="4"
          class="text-white d-flex mb-3 ml-auto mr-auto"
        />
        <h1 class="mt-auto text-white">Reach your goals</h1>
        <p class="text-white ml-2 mr-2 mb-5">Set weekly, monthly and yearly earning goals and track your progress towards them.</p>
        <p class="text-white ml-2 mr-2 mt-5">and then let Tipwise calculate how much money you've earned from hours worked and gratuities</p>
        <p class="text-white ml-2 mr-2 mt-5"></p>


      </div>
      
      <div class="text-center"
        @click="scrollToElement('section-1')"
      >
        <b-icon
          icon="arrow-up-circle"
          font-scale="3"
          class="tipwise-primary d-flex mb-auto mt-5 ml-auto mr-auto"
        />
        <p class="tipwise-primary mr-2 ml-2 mt-2">Get Started</p>
      </div>

      <p></p>

    </div>
	</div>
</template>

<script>
export default {

  mounted() {
  },

  methods: {
    scrollToElement(section) {
      this.$refs[section].scrollIntoView({behavior: 'smooth'});
    }
  }


}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/components/views/landing.scss';
</style>
