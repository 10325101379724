<template>
  <form action="" @submit.prevent="resetPassword()">
    <div class="vh-90 d-flex justify-content-center align-items-center">
    <div class="content-box sign-in">
      <h1 class="d-flex justify-content-center tipwise-primary">Reset Password</h1>
      <span class="d-flex justify-content-center tipwise-grey mb-5">All set, enter your new password</span>

      <div class="mt-5">
        <b-form-input v-model="credentials.password" placeholder="password" type="password" size="lg" class="mt-2 input-tipwise"/>
      </div>
      
      <div class="d-flex justify-content-center mt-5">
        <b-button type="submit" variant="tipwise-white">
          Reset password
        </b-button>
      </div>
    </div>
  </div>
  </form>
</template>

<script>
import router from '@/router/index'

export default {
    name: 'ResetPassword',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Reset Password'
    },

  props: {
    token: {
    type: String,
    default: null,
    }
  },

  data(){
    return{
      credentials: {
        password: '',
      },
    }
  },

    methods: {
     resetPassword() {
       this.$store.dispatch("user/resetPassword", { ...this.credentials, token: this.token })
        .then((response) => { 
          this.$notify({group: 'default', text: response.data.message })
          router.push({ name: 'sign-in' })
        })
        .catch((error) => { 
          this.$notify({ 
            group: 'default', 
            text:`Error: ${ error.response.data.message }`,
            type: 'error'
          } ); })
    },
  },
};
</script>
