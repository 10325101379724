export default {
  methods: {
    getFirstDayOfWeek(date = Date.today(), startOfWeek = 2)  {
      const firstDayWeek = new Date(date);
      while (firstDayWeek.getDay() !== startOfWeek - 1) {
        firstDayWeek.setDate(firstDayWeek.getDate() - 1);
      }
      return firstDayWeek;
    },

    getFirstDayOfMonth(d = Date.now()) {
      const date = new Date(d);
      const firstDayMonth = new Date(
        date.getFullYear(),
        date.getMonth(),
        1,
      );
      
      return firstDayMonth
    },

    getFirstDayOfYear(d = Date.now()) {      
      const date = new Date(d);
      const firstDayYear = new Date(
        date.getFullYear(),
        0,
        1,
      );

      return firstDayYear
    },
  }
};
