<template>
  <div>
    <h1 class="d-flex justify-content-center mt-5 tipwise-primary text-capitalize">{{ job.name }}</h1>
    <span class="d-flex justify-content-center tipwise-grey mb-5"></span>

    <div class="content-box">
      <div class="d-flex mr-3 ml-3 mt-3">	
				<span>Job Name:</span>
        <span class="ml-auto text-capitalize">{{ job.name }}</span>
			</div>
			<div class="d-flex mr-3 ml-3 mt-3">	
				<span>Base Pay:</span>
        <span class="ml-auto">${{ round(job.base_pay) }}/h</span>
			</div>

      <hr>

      <div class="d-flex mr-3 ml-3 mt-3">	
				<span>Total shifts worked:</span>
        <span class="ml-auto">{{ round(job.total_shifts, 0) }}</span>
			</div>
      <div class="d-flex mr-3 ml-3 mt-3">	
				<span>Total tips earned:</span>
        <span class="ml-auto">${{ round(job.total_tips) }}</span>
			</div>
      <div class="d-flex mr-3 ml-3 mt-3">	
				<span>Total hours worked:</span>
        <span class="ml-auto">{{ round(job.total_hours) }}</span>
			</div>

      <hr>

      <div class="d-flex mr-3 ml-3 mt-3">	
				<span>Average hourly wage:</span>
        <span class="ml-auto">${{ round(job.average_per_hour) }}</span>
			</div>
      <div class="d-flex mr-3 ml-3 mt-3">	
				<span>Total earnings:</span>
        <span class="ml-auto">${{ round(job.total_earnings) }}</span>
			</div>     

		</div>
		<router-link :to="{ name: 'edit-job', params: { id: this.job.id } }" tag="button" class="btn btn-tipwise w-100 mt-2">
			Edit Job
		</router-link>
		<router-link :to="{ name: 'job-settings' }" tag="button" class="btn btn-tipwise-white w-100 mt-2">
			Back
		</router-link>
</div>
</template>

<script>
import numberFormatMixin from "../../components/Mixins/numberFormatMixin";

export default {
	mixins: [numberFormatMixin],

  computed: {
		job(){
			return this.$store.state.jobs.jobs.find(job => job.id == this.$route.params.id)
		}
  },
}
</script>
