<template>
	<div>
		<h1 class="text-center mb-2 tipwise-primary mt-5">Hi there</h1>
		<span class="d-flex justify-content-center tipwise-grey mb-5">Thanks for checking out my app 🙂</span>

		<div class="content-box">
			<h5 class="mt-3 text-center tipwise-primary">About</h5>
			<hr class="tipwise-primary">
			<p class="m-3 text-center" >
				Tipwise is an app created by me Conor. A former busser turned software engineer who had the problem of keeping track of his earnings.
				This is the app I wish I had when I was working in service, I hope you like it 🤓
			</p>
			
			<h5 class="mt-5 text-center tipwise-primary">Platforms</h5>
			<hr class="tipwise-primary">
			<p class="m-3 text-center" >Tipwise is available on:</p>

      <p class="m-3 text-center" >
				<a href="https://apps.apple.com/us/app/tipwise-tip-tracker/id1625474163">IOS</a>
				<span class="m-2"> | </span>
				<a href="https://play.google.com/store/apps/details?id=com.tipwiseapp.twa">Android</a>
				</p>

			<h5 class="mt-5 text-center tipwise-primary">Contact</h5>
			<hr class="tipwise-primary">

			<p class="m-3 text-center" >Join the conversation on <a href="https://discord.gg/uNFQGj4whd" target="_blank">Discord</a>
			<!-- <p class="m-3 text-center" >weigh in on what I develop next over at <a href="https://tipwise.kampsite.co" target="_blank">Kampsite</a></p>  -->
      <p class="m-3 text-center" >Or if you'd prefer you can email me directly at <a href="mailto:conor@tipwiseapp.com">conor@tipwiseapp.com</a></p>

			<h5 class="mt-5 text-center tipwise-primary">Change Log</h5>
			<hr>

			<p>Date: 03/07/2022</p>
			<p>Summary: Date format should use first day for range</p>
			<p>Type: Feature</p>
			<p>Reporter: Mkat - Discrod</p>

			<hr>
			<p>Date: 04/05/2022</p>
			<p>Summary: Date Reports showing records outside of selected date range</p>
			<p>Type: Bug</p>
			<p>Reporter: Mkat - Discrod</p>

			<hr>
			<p>Date: 03/05/2022</p>
			<p>Summary: Configure first day of week for calendar</p>
			<p>Type: Feature</p>
			<p>Reporter: Kevin - Email</p>

			<hr>
			<p>Date: 10/04/2022</p>
			<p>Summary: CSV export for reports</p>
			<p>Type: Feature</p>
			<p>Reporter: Conor</p>			

			<hr>
			<p>Date: 10/04/2022</p>
			<p>Summary: Calculate average tip percentage</p>
			<p>Type: Feature</p>
			<p>Reporter: Soundbite - Discord</p>		
			

			<router-link tag="button" :to="{ name: 'settings' }" class="btn btn-tipwise-white m-1 w-100  text-center">Back</router-link>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
