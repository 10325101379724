<template>
  <div class="new-shift">
    <h1 class="d-flex justify-content-center mt-5 tipwise-primary">New Shift</h1>
    <span class="d-flex justify-content-center tipwise-grey mb-5">here you can create a new shift record</span>

    <b-form 
      @submit="onSubmit" 
      class="content-box new-shift-box" 
      autocomplete="off"
     >

      <div class="mt-3">
        <span class="tipwise-grey">Date</span>
        <b-form-datepicker id="input-date" v-model="form.date" class="mb-2 input-tipwise" size="lg" />
      </div>

        <span class="tipwise-grey">Job</span>
        <b-form-select
          id="input-3"
          v-model="form.job_id"
          :options="getJobArray()"
          required
          class="mb-2 input-tipwise"
          size="lg"
        />
        

      <span class="tipwise-grey">Hours worked</span>
      <b-form-input
        id="input-hours-worked"
        v-model="form.hours_worked"
        type="number"
        step="0.01"
        required
        placeholder="hours worked"
        class="mb-2 input-tipwise"
        size="lg"
      />
      

      <span class="tipwise-grey">Tips earned</span>
      <b-form-input
        id="input-tips-earned"
        v-model="form.tips_earned"
        type="number"
        step="0.01"
        required
        placeholder="Tips Earned"
        class="mb-2 input-tipwise"
        size="lg"
      />
      

      <span class="tipwise-grey">Tips paid out</span>
      <b-form-input
        id="input-tips-earned"
        v-model="form.tips_paid_out"
        type="number"
        step="0.01"
        required
        placeholder="Tips paid out"
        class="mb-2 input-tipwise"
        size="lg"
      />

      <div v-if="userSettings.track_total_sales">
        <span class="tipwise-grey">Total sales</span>
        <b-form-input
          id="input-tips-earned"
          v-model="form.total_sales"
          type="number"
          step="0.01"
          :required="false"
          placeholder="Total sales"
          class="mb-2 input-tipwise"
          size="lg"
        />
      </div>

      



      <b-button class="d-flex m-auto btn-tipwise btn-lg mt-5" :disabled="awaitingResponse" type="submit">Save</b-button>
    </b-form>
  </div>
</template>

<script>
export default {
  title: 'New Shift',

  metaInfo: {
      title: 'New Shift',
  },

  data() {
    return {
      awaitingResponse: false,
      form: {
        tips_earned: null,
        pay_rate: null,
        date: new Date().toISOString().slice(0,10),
        tips_paid_out: null,
        hours_worked: null,
        total_earned: null,
        total_sales: null,
        job_id: null,
      },
    }
  },

  beforeCreate() {
    this.$store.dispatch('jobs/get');
  },

  methods: {
    onSubmit(evt) {
      this.awaitingResponse = true;
      evt.preventDefault();

      this.$store.dispatch('shifts/create', this.form)
        .then((response) => {
          console.log(response);
          this.$notify({ group: 'default', type: 'success', text: 'Shift has been saved successfully' })
          this.$router.push( { name: 'view-shift', params: { id: response.data.id } } );
        })
        .catch((error) => { 
          this.awaitingResponse = false;
          console.log(error) 
        })
    },

    getJobArray() {
      const array = [{ value: null, text: 'Select Job', selected: true }];

      this.jobs.forEach((job) => {
        array.push({ value: job.id, text: job.name });
      })

      return array;
    }
  },

  computed: {
    jobs() {
      return this.$store.state.jobs.jobs.filter((job) => !job.disabled)
    },

    userSettings() {
      return this.$store.state.settings.settings;
    },
  }
}
</script>

<style lang="scss" scoped>
  // @import '../../assets/stylesheets/components/views/shifts/new-shift.scss';
</style>