<template>
  <div>
    <h1 class="text-center mt-5 tipwise-primary">New Job</h1>
    <p class="text-center tipwise-grey mb-5">here you can create a new job record</p>

    <b-form @submit="onSubmit" class="content-box mt-5">

      <span class="tipwise-grey">Job Name</span>
      <b-form-input
        id="input-job-name"
        v-model="form.name"
        type="text"
        required
        placeholder="Name"
        class="mb-2 input-tipwise"
        size="lg"
      >
      </b-form-input>

      <span class="tipwise-grey">Base Pay</span>
        <b-form-input
        id="input-job-base-pay"
        v-model="form.base_pay"
        type="number"
        step="0.01"
          required
          placeholder="Base Pay "
          class="mb-2 input-tipwise"
          size="lg"
        >
        </b-form-input>

      <b-button class="btn-tipwise btn w-100 m-1" type="submit">Save</b-button>
      <router-link tag="b-button" :to="{ name: 'job-settings' }" class="btn-tipwise-white btn w-100 m-1" type="submit">Back</router-link>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        base_pay: null,
      },
    }
  },

  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      console.log(JSON.stringify(this.form));
      this.$store.dispatch('jobs/create', this.form)
        .then(() => {
          this.$notify({
            group: 'default',
            type : 'success',
            text: 'Job has been saved successfully 🙏',
          });
        })
        .then(() => { this.$router.push({ name: 'job-settings' }) })
        .catch((error) => {
          this.$notify({
            group: 'default',
            type: 'error',
            text: error,
          });
        })
    },
  },
}
</script>

<style scoped>

</style>