<template>
  <div class="home w-100 text-center mt-3">
    <div class="container ml-auto mr-auto">
      <div class="">
        <donut-chart class="mt-5"/>
      </div>

      <div class="container content-box donut-container">
        <div class="ml-auto mr-auto mt-2">
          <p class="tipwise-grey small text-left">Tell your friends 🤝</p>

          <p class="tipwise-primary" @click="shareReferralCode()"> 
            {{ `https://tipwiseapp.com/sign-up?ref=${ user.referralCode }` }}
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import DonutChart from "@/components/DonutChart";

export default {
  name: 'Home',
  metaInfo: {
      title: 'Dashboard',
  },

  components: {
    DonutChart,
  },

  mounted() {
    this.$store.dispatch("shifts/getAll");
  },

  data() {
    return {

    };
  },

  computed: {
    shifts() {
      if (Object.keys(this.$store.state.shifts.shifts).length !== 0) {
        return this.$store.state.shifts.shifts.sort((a, b) => new Date(a.date) - new Date(b.date)).reverse()
      }

      return false
    },

    user() {
      return this.$store.state.user;
    },

    // return the last 10 shifts
    recentShifts() {
      if (this.shifts || this.shifts < 10) return this.shifts;

      return this.shifts.slice(0, 10);
    }
  },

  methods: {
    getShiftDay(date) {
      const shiftDate = new Date(`${date}T00:00:00`)

      return shiftDate.toString().split(' ').slice(0, 3).join(' ');
    },

    // TODO make a mixin for this
    getJobById(jobId) {
      if (Object.keys(this.$store.state.jobs.jobs).length != 0) return this.$store.state.jobs.jobs.find(job => job.id === jobId);
      return false
    },

    shareReferralCode() {
      if (navigator.share) {
        navigator.share({
          title: 'Tipwise App',
          text: 'You should check out Tipwise',
          url: `sign-up?ref=${ this.user.referralCode }`,
          })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/components/views/home.scss';
</style>