import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    calendarShifts: []
  },

  mutations: {
    set(state, data) {
      Object.assign(state, {
        calendarShifts: data
      });
    },

    add(state, data) {
      state.calendarShifts.push(data);
    }
  },

  actions: {
    clean({ commit }) {
      commit('set', {});
    },

    setAll({ commit }) {
      return new Promise((resolve, reject) => {
        commit('set', [])
        .then(() => {
          let shifts = this.state.shifts.shifts.map((shift) => { 
            return {
              dates: new Date(shift.date),
              dot: true,
            } 
          })
          commit('set', shifts)
          Promise.resolve()
        })
        .catch(() => Promise.reject())
      })
    },

    setLocalDate({ commit }, payload) {
      commit('add', payload)
    },
  },
};
