<template>
	<div>
		<h1 class="text-center mb-2 tipwise-primary mt-5">Calendar Settings</h1>
		<span class="d-flex justify-content-center tipwise-grey mb-5">Edit your calendar settings</span>

		<div class="content-box">

    <div class="d-flex m-3 justify-content-between">
			<span>First day of the week</span>
			<b-form-select
        :disabled="!this.editEnabled"
        v-model="settings.first_day_of_week" 
        :options="options"
        class="w-50"
      />
		</div>

		<router-link tag="button" :to="{ name: 'settings' }" class="btn btn-tipwise-white m-1">Back</router-link>
		<button v-if="this.editEnabled" class="btn btn-tipwise m-1" @click="updateSettings()">Save</button>
		<button v-else class="btn btn-tipwise m-1" @click="toggleEdit()">Edit</button>

		</div>
	</div>
</template>

<script>
import ToggleButton from 'vue-js-toggle-button'


export default {
	title: 'ShiftSettings',
  name: 'ShiftSettings',
  
	components: [ ToggleButton ],

	metaInfo: {
    title: 'Shift Settings',
  },
	
	data() {
		return {
      options: [
          { value: 1, text: 'Sunday' },
          { value: 2, text: 'Monday' },
          { value: 3, text: 'Tuesday' },
          { value: 4, text: 'Wednesday' },
          { value: 5, text: 'Thursday' },
          { value: 6, text: 'Friday' },
          { value: 7, text: 'Saturday' },
      ],
			settings: {
				id: null,
        first_day_of_week: null
			},
			editEnabled: false
		}
	},

	beforeMount() {
		Object.keys(this.userSettings).forEach((a) => { return this.settings.hasOwnProperty(a) ? this.settings[a] = this.userSettings[a] : false  });
	},

	computed: {
		userSettings() {
			return this.$store.state.settings.settings;
		}
	},

	methods: {
		toggleEdit() {
			this.editEnabled = !this.editEnabled;
		},

		updateSettings() {
			this.$store.dispatch('settings/update', this.settings)
				.then(() => { this.$notify({ group: 'default', text: 'Settings Updated!'}) })
				.then(() => { this.editEnabled = false })
        .catch(error => this.$notify({ group: 'default', type: 'error',  text: `Error: ${error}` }))
		}
	}
}
</script>

<style>

</style>
