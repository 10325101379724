import Vue from 'vue'
import Notifications from 'vue-notification'
import App from './App.vue'
import router from './router'
import store from './store'
import { BIconBarChart, BIconCalendarWeek, BIconGear, BIconCardList, BIconArrowDownCircle } from 'bootstrap-vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import VCalendar from 'v-calendar';
import Avatar from 'vue-avatar'
import VueMeta from 'vue-meta'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/stylesheets/_general.scss'
import './registerServiceWorker'
import ToggleButton from 'vue-js-toggle-button'

// Toggle componenet
Vue.use(ToggleButton)

// import apex charts
Vue.use(VueApexCharts)

// Install BootstrapVue
Vue.use(BootstrapVue)

// vue meta data helper
Vue.use(VueMeta)

// use bootstrap icons
Vue.component('BIconBarChart', BIconBarChart)
Vue.component('BIconCalendarWeek', BIconCalendarWeek)
Vue.component('BIconGear', BIconGear)
Vue.component('BIconCardList', BIconCardList)
Vue.component('BIconArrowDown', BIconArrowDownCircle)


// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.component('Avatar', Avatar)

// Vue notifications
Vue.use(Notifications)

Vue.use(VCalendar);

// register component apex charts
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
