import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index.js';
import Meta from 'vue-meta';

import Home from '@/views/Home.vue';
import About from '@/views/Home.vue';
import SignIn from '@/views/auth/SignIn.vue';
import SignUp from "@/views/auth/SignUp.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import NewShift from "@/views/shifts/NewShift.vue";
import EditShift from "@/views/shifts/EditShift.vue";
import Calendar from "@/views/Calendar.vue";
import Settings from "@/views/settings/Settings.vue";
import NewJob from "@/views/jobs/NewJob.vue";
import JobsList from '@/views/jobs/JobsList.vue';
import FilterShifts from '@/views/shifts/FilterShifts.vue';
import ViewShift from '@/views/shifts/ViewShift.vue';
import ProfileSettings from '@/views/settings/ProfileSettings.vue';
import JobSettings from '@/views/settings/JobSettings.vue';
import GoalSettings from '@/views/settings/GoalSettings.vue';
import TrackingSettings from '@/views/settings/TrackingSettings.vue';
import EditJob from '@/views/jobs/EditJob.vue';
import ViewJob from '@/views/jobs/ViewJob.vue';
import NotFound from '@/views/NotFound.vue';
import Welcome from '@/views/Welcome.vue';
import Landing from '@/views/Landing.vue';
import Report from '@/views/shifts/Report.vue';
import ShiftSettings from '@/views/settings/ShiftSettings.vue';
import CalendarSettings from '@/views/settings/CalendarSettings.vue';

Vue.use(VueRouter)
Vue.use(Meta)

  const routes = [
  {
    path: '/',
    name: 'landing',
    component: Landing,
    meta: {
      requireAuth: false,
      showBottomNav: false
    },
    beforeEnter(to, from, next) {
      if (localStorage.vuex && JSON.parse(localStorage.vuex).user.auth_token) {
        next({ name: 'home' });
      } else {
        next();
      }
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignIn,
    beforeEnter(to, from, next) {
      if (localStorage.vuex && JSON.parse(localStorage.vuex).user.auth_token) {
        next({ name: 'home' });
      } else {
        next();
      }
    }
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUp,
    props: (route) => ({ referralCode: route.query.ref }),  
    beforeEnter(to, from, next) {
      if (localStorage.vuex && JSON.parse(localStorage.vuex).user.auth_token) {
        next({ name: 'home' });
      } else {
        next();
      }
    }
  },
  {
    path: '/shifts/new',
    name: 'new-shift',
    component: NewShift,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
  {
    path: '/shifts/filter',
    name: 'filter-shifts',
    component: FilterShifts,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
  {
    path: '/shifts/edit/:id',
    name: 'edit-shift',
    component: EditShift,
    props: true,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },

  {
    path: '/shifts/view/:id',
    name: 'view-shift',
    component: ViewShift,
    props: true,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },

  {
    path: '/jobs/new',
    name: 'new-job',
    component: NewJob,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },

  {
    path: '/jobs/edit/:id',
    name: 'edit-job',
    component: EditJob,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },

  {
    path: '/jobs/view/:id',
    name: 'view-job',
    component: ViewJob,
    props: true,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },

  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
  {
    path: '/settings/profile',
    name: 'profile-settings',
    component: ProfileSettings,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
  {
    path: '/settings/jobs',
    name: 'job-settings',
    component: JobSettings,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
  {
    path: '/settings/goals',
    name: 'goal-settings',
    component: GoalSettings,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
  {
    path: '/settings/tracking',
    name: 'tracking-settings',
    component: TrackingSettings,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: JobsList,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
  { 
    path: '/404', 
    component: NotFound,
  },  
  { 
    path: '*', 
    redirect: '/404',
  },
  {
    path: '/welcome ',
    name: 'welcome',
    component: Welcome,
  },
  {
    path: '/report ',
    name: 'report',
    component: Report,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    props: (route) => ({ token: route.query.token }),  
  },
  {
    path: '/settings/shift-settings',
    name: 'shift-settings',
    component: ShiftSettings,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
  {
    path: '/settings/calendar-settings',
    name: 'calendar-settings',
    component: CalendarSettings,
    meta: {
      requireAuth: true,
      showBottomNav: true
    },
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})


router.beforeEach(async (to, from, next) => {
  const store = await import('@/store/index');
  const authenticated = store.default.getters['user/authenticated']

  if (!authenticated && to.meta.requireAuth) { next({ name: 'sign-in' }) }
  else next()
})

export default router
