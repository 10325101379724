import Vue from 'vue'
import Vuex from 'vuex'
import 'es6-promise/auto';
import createPersistedState from "vuex-persistedstate";
import userModule from './modules/user';
import jobsModule from './modules/jobs';
import shiftsModule from './modules/shifts';
import settingsModule from './modules/settings';
import calculationsModule from './modules/calculations';
import calendarShiftsModule from './modules/calendarShifts';

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
    createPersistedState()
  ],

  state: {
    auth_token: ''
  },

  modules: {
    user: userModule,
    jobs: jobsModule,
    shifts: shiftsModule,
    settings: settingsModule,
    calculations: calculationsModule,
    calendarShifts: calendarShiftsModule,
  },

})

export default store;

