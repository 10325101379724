<template>
  <div class="vh-90 d-flex justify-content-center align-items-center">
    <div class="sign-up content-box sign-in">
      <h1 class="d-flex justify-content-center tipwise-primary mb-5">Sign Up</h1>
      <b-form-input v-model="credentials.name" placeholder="name" type="text" class="mt-2 input-tipwise" size="lg"/>
      <b-form-input v-model="credentials.email" placeholder="email" type="email" class="mt-2 input-tipwise" size="lg"/>
      <b-form-input v-model="credentials.password" placeholder="password" type="password" class="mt-2 input-tipwise" size="lg"/>
      <!-- <TextInput v-model="credentials.password_confirmation" :field="fields.passwordConfirmationField"/> -->
      <b-link class="tipwise-primary" :to="{ name: 'sign-in' }">Already registered?</b-link>

      <div class="d-flex justify-content-center mt-5">
        <b-button @click.prevent="signUp" :disabled="this.awaitingResponse" type="button" variant="tipwise-white">
          Sign Up
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router/index';

export default {

  name: 'SignUp',
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Sign Up'
  },

  props: {
    referralCode: {
      type: String,
      default: null,
    }
  },

  data(){    
    return{
      awaitingResponse: false,
      credentials: {
        name: '',
        email: '',
        password: '',
      },
    }
  },

  methods: {
    signUp() {
      this.awaitingResponse = true;
      
      this.$store.dispatch("user/signUp", { user: this.credentials, referral_code: this.referralCode })
          .then(async () => await this.$store.dispatch('user/get'))
          .then(async () => await this.$store.dispatch('shifts/getAll'))
          .then(async () => await this.$store.dispatch('jobs/get'))
          .then(async () => await this.$store.dispatch('settings/get'))
          .then(async () => await router.push({ name: 'home' }))
          .then(async () => await this.$store.dispatch('calendarShifts/setAll'))
          .then(async () => this.awaitingResponse = false)
          .then(() => { router.push({ name: 'home' }) })
          .catch((error) => { 
            this.$notify( { group: 'default', text:`Error: ${ error.response.data.message } 💩`, type: 'error'} ); 
            this.awaitingResponse = false;
            })
    },
  },

}
</script>

<style scoped>
@import '../../assets/stylesheets/components/views/auth/sign-up.scss';
</style>
