
export default {
  methods: {
    round(value, to = 2) {
      if (!value) { value = 0 }
    
      return Number(value).toFixed(to);
    },
  }
};
