<template>
	<div>
		<h1 class="text-center mb-2 tipwise-primary">Tracking</h1>
		<span class="d-flex justify-content-center tipwise-grey mb-5">Configure Tipwise to suit you</span>

		<div class="content-box">
			
		</div>

		<router-link tag="button" :to="{ name: 'settings' }" class="btn btn-tipwise-white">Back</router-link>
	</div>
</template>

<script>
export default {
	title: 'Profile',
  name: 'Profile',
}
</script>

<style>

</style>