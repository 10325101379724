import Vue from 'vue';
import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    settings: {}
  },

  mutations: {
    set(state, data) {
      state.settings = data
    },
  },

  actions: {
    clean({ commit }) {
      commit('set', {});
    },

    get({ commit }) {
      ajax.get('/settings')
        .then((response) => { commit('set', response.data )})
        .catch((error) => { throw error })
    },

    update({ commit }, payload) {
      return new Promise((resolve, reject) => {
        ajax.put(`settings/${payload.id}`, payload)
        .then((response) => {
          commit('set', response.data);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
      })
    },
  },
};
