<template>
  <div>
    <h1 class="d-flex justify-content-center mt-5 tipwise-primary">Reports</h1>
    <span class="d-flex justify-content-center tipwise-grey mb-5">Here you can find reports for mutliple shifts</span>

    <div class="content-box"> 
      <span class="ml-3">Shifts:</span>
      <hr>
      <div v-for="shift in reportShifts" :key="shift.id">
        <div class="d-flex mr-3 mb-3 ml-3">	
          <span>{{ new Date(`${shift.date}T00:00:00`).toDateString() }}</span>	 
          <span class="m-auto text-capitalize">{{ shift.job_name }}</span>
          <span>${{ round(shift.total_earned) }}</span>
        </div>
      </div>
      <hr>
      <span class="ml-3">Totals:</span>
      <hr>
			<div class="d-flex mr-3 mb-3 ml-3">	
				<span>Total Hours worked:</span>	 
				<span class="ml-auto">{{ round(reportTotals.hours_worked) }}</span>
			</div>
			<div class="d-flex mr-3 mb-3 ml-3">	
				<span>Toal wage:</span>	 
				<span class="ml-auto">${{ round(reportTotals.pay)}}</span>
			</div>	
			<div class="d-flex mr-3 mb-3 ml-3">	
				<span>Total tips Earned:</span>	 
				<span class="ml-auto">${{ round(reportTotals.tips_earned) }}</span>
			</div>
			<div class="d-flex mr-3 mb-3 ml-3">	
				<span>Total tips paid out:</span>	 
				<span class="ml-auto">${{ round(reportTotals.tips_paid_out) }}</span>
			</div>
			<hr class="mt-2 mb-2">
			<div class="d-flex mr-3 ml-3 mb-3">	
				<span>Total Earned:</span>
				<span class="ml-auto">${{ round(reportTotals.total_earned) }}</span>
			</div>
		</div>

      <button @click="downloadReport()" class="btn btn-tipwise-white w-100 mt-2">
        Download
      </button>

      <router-link :to="{ name: 'filter-shifts' }" tag="button" class="btn btn-tipwise w-100 mt-2">
        Back
      </router-link>
  </div>
</template>

<script>
import numberFormatMixin from "../../components/Mixins/numberFormatMixin";
import ajax from '../../ajax.js';

export default {

  metaInfo: {
      title: 'Reports',
  },

  mixins: [numberFormatMixin],

  data() {
    return {
      reportFields: [
        'date',
        'job_name',
        'hours_worked',
        'total_earned',
        ]
    };
  },

  methods: {
    downloadReport() {
      ajax.get('user_shifts/report_csv', { params: this.$route.params, responseType: 'blob' })
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `tipwise-report-${new Date().toISOString().slice(0,10)}.csv`);
        document.body.appendChild(fileLink);

        fileLink.click();
      })
    }
  },

  computed: {
    reportShifts() {
      return this.$store.state.shifts.shiftReport.shifts;
    },

    reportTotals() {
      return this.$store.state.shifts.shiftReport.meta.totals;
    },

    reportJobs() {
      return this.$store.state.shifts.shiftReport.meta.jobs;
    }
  }

}
</script>

<style scoped>

</style>
