<template>
	<div>
		<h1 class="text-center mb-2 tipwise-primary mt-5">Shift Settings</h1>
		<span class="d-flex justify-content-center tipwise-grey mb-5">Edit your shift settings</span>

		<div class="content-box">

      <!-- <toggle-input label="Track total sales" :disabled="false" :value="settings.track_total_sales"/> -->
		<div class="d-flex m-3 justify-content-between">
			<span>Track total sales</span>
			<toggle-button
				v-model="settings.track_total_sales"
				:disabled="!this.editEnabled"
				labels
			/>
		</div>

		<!-- <div class="d-flex m-3 justify-content-between"> //todo
			<span>Track card tips</span>
			<toggle-button
				v-model="settings.track_card_tips"
				:disabled="!this.editEnabled"
				labels
			/>
		</div> -->

		<!-- <div class="d-flex m-3 justify-content-between"> //todo
			<span>Track total sales</span>
			<toggle-button
				v-model="settings.track_hours_and_minutes"
				:disabled="!this.editEnabled"
				labels
			/>
		</div> -->

		<router-link tag="button" :to="{ name: 'settings' }" class="btn btn-tipwise-white m-1">Back</router-link>
		<button v-if="this.editEnabled" class="btn btn-tipwise m-1" @click="updateSettings()">Save</button>
		<button v-else class="btn btn-tipwise m-1" @click="toggleEdit()">Edit</button>

		</div>
	</div>
</template>

<script>
import ToggleButton from 'vue-js-toggle-button'


export default {
	title: 'ShiftSettings',
  name: 'ShiftSettings',
  
	components: [ ToggleButton ],

	metaInfo: {
    title: 'Shift Settings',
  },
	
	data() {
		return {
			settings: {
				id: null,
        track_total_sales: false,
        track_card_tips: false,
        track_hours_and_minutes: false,
			},
			editEnabled: false
		}
	},

	beforeMount() {
		Object.keys(this.userSettings).forEach((a) => { return this.settings.hasOwnProperty(a) ? this.settings[a] = this.userSettings[a] : false  });
	},

	computed: {
		userSettings() {
			return this.$store.state.settings.settings;
		}
	},

	methods: {
		toggleEdit() {
			this.editEnabled = !this.editEnabled;
		},

		updateSettings() {
			this.$store.dispatch('settings/update', this.settings)
				.then(() => { this.$notify({ group: 'default', text: 'Settings Updated!'}) })
				.then(() => { this.editEnabled = false })
        .catch(error => this.$notify({ group: 'default', type: 'error',  text: `Error: ${error}` }))
		}
	}


}
</script>

<style>

</style>
