<template>
  <div
    class="list-tile ml-auto mr-auto h-100 p-2"
    @click="$router.push({ name: 'view-shift', params: { id: shift.id, returnPath: returnPath } })"
  >
    <div class="list-tile-date d-flex align-items-center">
      <div class="row d-flex w-100">
        <div class="col-4 mt-auto mb-auto">
          <avatar class="m-auto" :size="40" :username="getJobById(shift.job_id).name"></avatar>
        </div>
        <div class="col-4 mt-auto mb-auto text-left">
          <span class="small tipwise-grey">{{getShiftDay(shift.date)}}</span>
          <br>
          <span>{{ getJobById(shift.job_id).name }}</span>
        </div>

        <div class="col-4 ml-auto">
          <span class="small tipwise-grey">Total</span>
          <br>
          <span>${{ round(shift.total_earned) }}</span>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
import numberFormatMixin from "./Mixins/numberFormatMixin";

export default {
  props: {
    shift: Object,
    returnPath: String
  },

  mixins: [numberFormatMixin],

  methods: {
    getJobById(jobId) {
      if (Object.keys(this.$store.state.jobs.jobs).length != 0) return this.$store.state.jobs.jobs.find(job => job.id === jobId);

      return false
    },

    getShiftDay(date) {
      const shiftDate = new Date(`${date}T00:00`)

      return shiftDate.toString().split(' ').slice(1, 3).join(' ');
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @import '../assets/stylesheets/components/views/list-tile.scss';
</style>
