<template>
  <div class="jobs-list mt-5">
    <h1 class="text-center mb-2 tipwise-primary">Jobs</h1>
    <span class="d-flex justify-content-center tipwise-grey mb-5">Here is a list of all of your jobs</span>

    <hr class="mb-0">

    <button @click="$router.push({ name: 'new-job' })" class="btn btn-tipwise-white mt-2 w-100" >New Job</button>

    <div v-for="job in jobs" :key="`job-${job.id}`">
      <div class="p-2 mb-md-3 job-container" v-b-toggle="`collapse-job-${job.id}`">
        <div class="m-auto">
          <span>{{ job.name }}</span>
          <p class="tipwise-grey">{{ job.shifts.length }} shifts</p>
        </div>

        <b-collapse tag="div" v-b-visible="true" :id="`collapse-job-${job.id}`">
          <div v-for="shift in job.shifts" :key="`job-${shift.job_id}-shift-${shift.id}`">
            <list-tile :shift="shift" :returnPath="'jobs'" />
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import ListTile from "@/components/ListTile";

export default {
  title: 'Job List',
  metaInfo: {
    title: 'Jobs',
  },

  components: {
    ListTile
  },

  computed: {
    jobs() {
      return this.$store.state.jobs.jobs
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/stylesheets/components/views/jobs/jobs-list.scss';
</style>